<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="処分事業者編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 会社情報 ======================================================================== -->
        <div
          class="l-contents l-each"
          v-for="(f, index) in form"
          :key="'form-' + index"
        >
          <div class="l-box u-space-btwn">
            <h3 class="c-lead icon">処分場{{ index + 1 }}</h3>
            <div
              class="c-btn secondary sm delete"
              @click="deleteFormHandler(f.formId, index)"
            >
              削除
            </div>
          </div>
          <div class="l-box">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>処分場名</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <InputText
                    :value.sync="f.disposalSiteName"
                    inputType="text"
                    placeholder="処分場名"
                    tooltip="100文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="100"
                    :isValid.sync="
                      getTargetValidate(f.formId).disposalSiteName.isValid
                    "
                    :errMsg.sync="
                      getTargetValidate(f.formId).disposalSiteName.errMsg
                    "
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="f.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="
                          getTargetValidate(f.formId).zipCode.isValid
                        "
                        :errMsg.sync="
                          getTargetValidate(f.formId).zipCode.errMsg
                        "
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(f.addressInfo)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="f.addressInfo.selectedPrefectureTemp"
                        :validation="validateJustBlank"
                        :change="() => changeAddressInfoHandler(f.formId, true)"
                        :isValid.sync="
                          getTargetValidate(f.formId).prefecturesId.isValid
                        "
                        :errMsg.sync="
                          getTargetValidate(f.formId).prefecturesId.errMsg
                        "
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="f.addressInfo.address"
                        inputType="text"
                        placeholder="市町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :change="() => changeAddressInfoHandler(f.formId, true)"
                        :isValid.sync="
                          getTargetValidate(f.formId).address.isValid
                        "
                        :errMsg.sync="
                          getTargetValidate(f.formId).address.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="f.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          getTargetValidate(f.formId).buildingName.isValid
                        "
                        :errMsg.sync="
                          getTargetValidate(f.formId).buildingName.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="f.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="getTargetValidate(f.formId).tel.isValid"
                  :errMsg.sync="getTargetValidate(f.formId).tel.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="f.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="getTargetValidate(f.formId).fax.isValid"
                  :errMsg.sync="getTargetValidate(f.formId).fax.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>JWNETID(加入者番号)</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="f.jwnetContractNo"
                    inputType="text"
                    placeholder="JWNETID(加入者番号)"
                    tooltip="7桁の半角英数字で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="7"
                    :isValid.sync="
                      getTargetValidate(f.formId).jwnetContractNo.isValid
                    "
                    :errMsg.sync="
                      getTargetValidate(f.formId).jwnetContractNo.errMsg
                    "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>EDI利用確認キー</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="f.ediKey"
                  inputType="text"
                  placeholder="EDI利用確認キー"
                  tooltip="8桁の半角英数字で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="8"
                  :isValid.sync="getTargetValidate(f.formId).ediKey.isValid"
                  :errMsg.sync="getTargetValidate(f.formId).ediKey.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業場コード</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="f.disposalStoreCode"
                  inputType="number"
                  placeholder="事業場コード"
                  tooltip="3桁の半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="3"
                  :isValid.sync="
                    getTargetValidate(f.formId).disposalStoreCode.isValid
                  "
                  :errMsg.sync="
                    getTargetValidate(f.formId).disposalStoreCode.errMsg
                  "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>公開確認番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="f.publicConfirmationNo"
                  inputType="number"
                  placeholder="公開確認番号"
                  tooltip="6桁の半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="6"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols wrap">
                  <div
                    v-for="item in f.disposalSiteData"
                    :key="`cb-${f.formId}-${item.id}`"
                    class="c-checkbox"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`cb-${f.formId}-${item.id}`"
                        type="checkbox"
                        :value="{ id: item.id }"
                        v-model="f.disposalSiteLicenceList"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`cb-${f.formId}-${item.id}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ item.licenceNo }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template>
            <div class="l-box"
              v-for="(finalDisposalSite, finalDisposalSiteIndex) in f.finalDisposalSiteList"
              :key="'finalDisposalSite-' + finalDisposalSiteIndex"
            >
              <div class="l-box u-space-btwn">
                <h3 class="c-lead icon">最終処分場{{ finalDisposalSiteIndex + 1 }}</h3>
                <!-- 何かしらのIndexを渡して削除？ -->
                <div
                  class="c-btn secondary sm delete"
                  @click="deleteFinalDisposalSiteHandler(index, finalDisposalSiteIndex)"
              >
                削除
                </div>
              </div>


              <div class="l-block-lg">
                <div class="c-inputWrap">
                  <div class="c-checkbox">
                    <input
                      :id="'isSC-' + index + finalDisposalSiteIndex"
                      type="checkbox"
                      v-model="isSameCompanyList[index][finalDisposalSiteIndex]"
                      @change="checkIsSameCompany(index, finalDisposalSiteIndex)"
                    />
                    <label class="c-checkbox__label" :for="'isSC-' + index + finalDisposalSiteIndex">
                      <span class="c-checkbox__box"></span>
                      企業情報と同じ
                    </label>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label required">
                    <label>最終処分場名</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                      :value.sync="finalDisposalSite.finalDisposalSiteName"
                      inputType="text"
                      placeholder="最終処分場名"
                      tooltip="100文字以内で入力してください"
                      :validation="validateMaxLen"
                      :validateArg="100"
                      :isValid.sync="
                        getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalSiteName.isValid
                      "
                      :errMsg.sync="
                        getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalSiteName.errMsg
                      "
                    />
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label required">
                    <label>住所</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>郵便番号</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div class="c-inputWrap__items__cols center sm-mgn">
                          <InputText
                              :value.sync="finalDisposalSite.addressInfo.zipCode"
                              inputType="text"
                              placeholder="1234567(ハイフンなし)"
                              tooltip="7桁の半角数字で入力してください。"
                              :validation="validateRequiredNumberEqualLen"
                              :validateArg="7"
                              :isValid.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].zipCode.isValid
                          "
                              :errMsg.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].zipCode.errMsg
                          "
                          />
                          <div
                              class="c-btn secondary sm"
                              @click="searchAddressHandler(finalDisposalSite.addressInfo)"
                          >
                            住所自動入力
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>都道府県</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <Selectbox
                              class=""
                              name="prefecture"
                              :value.sync="finalDisposalSite.addressInfo.selectedPrefectureTemp"
                              :validation="validateJustBlank"
                              :isValid.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].prefecture.isValid
                          "
                              :errMsg.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].prefecture.errMsg
                          "
                          >
                            <option
                                v-for="prefecture in prefectures"
                                :key="prefecture.id"
                                :value="prefecture.name"
                            >
                              {{ prefecture.name }}
                            </option>
                          </Selectbox>
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>市町村</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <InputText
                              :value.sync="finalDisposalSite.addressInfo.address"
                              inputType="text"
                              placeholder="市町村"
                              tooltip="100文字以内で入力してください"
                              :validation="validateMaxLen"
                              :validateArg="100"
                              :isValid.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].address.isValid
                          "
                              :errMsg.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].address.errMsg
                          "
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>番地建物名号室</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <InputText
                              :value.sync="finalDisposalSite.addressInfo.buildingName"
                              inputType="text"
                              placeholder="番地建物名号室"
                              tooltip="100文字以内で入力してください"
                              :validation="validateMaxLen"
                              :validateArg="100"
                              :isValid.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].building.isValid
                          "
                              :errMsg.sync="
                            getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].building.errMsg
                          "
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>電話番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                        :value.sync="finalDisposalSite.tel"
                        inputType="text"
                        placeholder="電話番号"
                        tooltip="半角数値で入力してください"
                        :validation="validateNumberMaxLen"
                        :validateArg="15"
                        :isValid.sync="
                      getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].tel.isValid
                    "
                        :errMsg.sync="
                      getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].tel.errMsg
                    "
                    />
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>事業場コード</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                        :value.sync="finalDisposalSite.finalDisposalStoreCode"
                        inputType="text"
                        placeholder="事業場コード"
                        tooltip="10桁以下の半角数値で入力してください"
                        :validation="validateNumberMaxLen"
                        :validateArg="10"
                        :isValid.sync="
                      getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalStoreCode.isValid
                    "
                        :errMsg.sync="
                      getTargetValidate(f.formId).finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalStoreCode.errMsg
                    "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="l-each sm l-box">
                <button 
                  class="c-btn secondary func add" 
                  @click="addFinalDisposalSiteHandler(index)"
                >
                <!-- クリックイベント追加予定 -->
                  最終処分場を追加
                </button>
              </div>
            </div>
          </template>
        </div>
        <!-- /.l-contents -->

        <div class="l-each sm l-box">
          <div class="c-btn secondary func add" @click="addDisposalSiteHandler">
            処分場を追加
          </div>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" :class="{ disabled: isDisableRegister}" @click="checkRequiredHandler">登録</div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          処分場を編集します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>
    <!-- 処分場削除時の登録モーダル -->
    <div class="c-modal" v-show="isConfirmDeleteModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          編集内容を登録します。<br />
          ルートに反映するために、<br />
          引き続き委託契約書情報の修正を行ってください。
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import AddressSelectModal from "../components/AddressSelectModal.vue";
import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidation from "../mixins/form/formValidation";
import { API_URL } from "@/const";
import common from "@/mixin/common";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "disposer-step2-edit",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    AddressSelectModal,
  },
  mixins: [validation, formValueEdit, callApi, addressSearch, formValidation, common],
  data() {
    return {
      disposalCompanyId: null,
      isAddressSelectModalShow: false,
      isConfirmModalShow: false,
      isConfirmDeleteModalShow: false,
      postData: null, // this.formをフォーマットして最終的にpostするデータ
      errMsgs: [],
      deleteFormFlag: false,
      isRemoveDisposalSite: false,
      processing: false,
      isDisableRegister: false,
      isSameCompanyList: {
        0: []
      },
    };
  },

  methods: {
    // 企業情報と同じチェックボックスを押下した場合
    checkIsSameCompany(index,finalDisposalSiteIndex) {
      let v = this.form[index];
      // チェック済みの場合、処分場の【最終処分場名・住所・電話番号】の情報を反映させる
      if (this.isSameCompanyList[index][finalDisposalSiteIndex]) {
        v.finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalSiteName = v.disposalSiteName;
        v.finalDisposalSiteList[finalDisposalSiteIndex].addressInfo.zipCode = v.addressInfo.zipCode;
        v.finalDisposalSiteList[finalDisposalSiteIndex].addressInfo.selectedPrefectureTemp = v.addressInfo.selectedPrefectureTemp;
        v.finalDisposalSiteList[finalDisposalSiteIndex].addressInfo.address = v.addressInfo.address;
        v.finalDisposalSiteList[finalDisposalSiteIndex].addressInfo.buildingName = v.addressInfo.buildingName;
        v.finalDisposalSiteList[finalDisposalSiteIndex].tel = v.tel;
      } else {
        v.finalDisposalSiteList[finalDisposalSiteIndex].finalDisposalSiteName = "";
        v.finalDisposalSiteList[finalDisposalSiteIndex].addressInfo = {
          zipCode: "",
          prefecturesId :null,
          address: "",
          buildingName: "",
          selectedPrefectureTemp: null
        }
        v.finalDisposalSiteList[finalDisposalSiteIndex].tel = "";
      }
    },
    // 取得したデータに応じて「企業情報と同じ」チェックボックスの判定枠を生成する
    updateCheckboxStatus() {
      for(let i = 0; i < this.form.length; i++) {
        for(let j = 0; j < this.form[i].finalDisposalSiteList.length; j++) {
          if(!this.isSameCompanyList[i]) {
            this.isSameCompanyList[i] = [false];
          } else {
            this.isSameCompanyList[i].push(false);
          }
        }
      }
    },
    // 住所系変更時に許可証を取得（API） DISPOSER.SITE_SELECT_LICENCE
    // disposalCompanyId、都道府県名、住所がパラメータ
    changeAddressInfoHandler(formId, isAbleChange) {
      let targetForm = this.form.filter((f) => f.formId === formId)[0];
      // 以下APIパラメータとして必要
      const prefecture = targetForm.addressInfo.selectedPrefectureTemp;
      const address = targetForm.addressInfo.address;
      axios
        .get(`${API_URL.DISPOSER.SITE_SELECT_LICENCE}?disposalCompanyId=${this.disposalCompanyId}&prefectures_name=${prefecture}&address=${address}`)
        .then((res) => {
          if (isAbleChange && !this.isRemoveDisposalSite) {
            targetForm.disposalSiteLicenceList = [];
          }
          this.$set(
            targetForm,
            "disposalSiteData",
            JSON.parse(JSON.stringify(res.data.disposalLicenceList))
          );

          if (res.data.disposalLicenceList.length === 1) {
            targetForm.disposalSiteLicenceList = [{ id: res.data.disposalLicenceList[0].id }];
          }
        })
        .then(() => {
          this.isRemoveDisposalSite = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 許可証情報を追加押下 v-model用form、validate用追加
    addDisposalSiteHandler() {
      const disposalLen = this.form.length;
      var newId = 1;
      if (disposalLen != 0) {
        newId = this.form[disposalLen - 1].formId + 1;
      }
      this.form = [
        ...this.form,
        {
          formId: newId,
          disposalCompanyId: this.disposalCompanyId,
          disposalSiteName: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            address: "",
            buildingName: "",
          },
          tel: "",
          fax: "",
          jwnetContractNo: "",
          ediKey: "",
          disposalStoreCode: "",
          publicConfirmationNo: "",
          disposalSiteLicenceList: [],
          disposalSiteData: [],
          finalDisposalSiteList: [
            {
              finalDisposalSiteName: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: null,
                address: "",
                buildingName: "",
              },
              tel: "",
              finalDisposalStoreCode: "",
            },
          ],
        },
      ];

      this.validate = [
        ...this.validate,
        {
          formId: newId,
          disposalSiteName: { isValid: true, errMsg: "" },
          zipCode: { isValid: true, errMsg: "" },
          prefecturesId: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          buildingName: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          jwnetContractNo: { isValid: true, errMsg: "" },
          ediKey: { isValid: true, errMsg: "" },
          disposalStoreCode: { isValid: true, errMsg: "" },
          publicConfirmationNo: { isValid: true, errMsg: "" },
          finalDisposalSiteList: [
            {
              finalDisposalSiteName: { isValid: true, errMsg: "" },
              zipCode: { isValid: true, errMsg: "" },
              prefecture: { isValid: true, errMsg: "" },
              address: { isValid: true, errMsg: "" },
              building: { isValid: true, errMsg: "" },
              tel: { isValid: true, errMsg: "" },
              finalDisposalStoreCode: { isValid: true, errMsg: "" },
            },
          ],
        },
      ];
      // 最終処分場の要素が追加されるため、チェックボックスの判定枠を追加
      if(!this.isSameCompanyList[this.form.length - 1]) {
        this.isSameCompanyList[this.form.length - 1] = [false];
      } else {
        this.isSameCompanyList[this.form.length - 1].push(false);
      }
    },

    // 最終処分場を追加を追加押下 v-model用form、validate追加
    addFinalDisposalSiteHandler(index) {
      const finalDisponsalSite = {
        finalDisposalSiteName: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        tel: "",
        finalDisposalStoreCode: "",
      };
      const validateFinalDisponsalSite = {
        finalDisposalSiteName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        prefecture: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        building: { isValid: true, errMsg: "" },
        tel: { isValid: true, errMsg: "" },
        finalDisposalStoreCode: { isValid: true, errMsg: "" },
      };
      this.form[index].finalDisposalSiteList.push(finalDisponsalSite);
      this.validate[index].finalDisposalSiteList.push(validateFinalDisponsalSite);
      // 最終処分場の要素が追加されるため、チェックボックスの判定枠を追加
      if(!this.isSameCompanyList[index]) {
        this.isSameCompanyList[index] = [false];
      } else {
        this.isSameCompanyList[index].push(false);
      }
    },

    // form削除 post用のformのOBJとバリデーション用のPBJを削除
    deleteFormHandler(formId, index) {
      this.form = this.form.filter((f) => f.formId != formId);
      this.validate = this.validate.filter((v) => v.formId != formId);
      this.isRemoveDisposalSite = true;
      // 押下&削除対象の要素(処分場)を起点に配列情報を詰める
      for (let i = index; i < Object.keys(this.isSameCompanyList).length; i++) {
        let tempList = this.isSameCompanyList[i + 1];
        this.isSameCompanyList[i] = tempList;
      }
    },

    // 最終処分場削除 post用の最終処分場のformのOBJとバリデーション用のPBJを削除
    deleteFinalDisposalSiteHandler(formIndex, listIndex) {
      this.form[formIndex].finalDisposalSiteList.splice(listIndex, 1);
      this.validate[formIndex].finalDisposalSiteList.splice(listIndex, 1);
      // 押下対象のチェックボックス状態管理の配列情報を削除する
      this.isSameCompanyList[formIndex].splice(listIndex, 1);
    },

    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      const deleteFlag = this.deleteFormFlag;

      axios.put(API_URL.DISPOSER.SITE_EDIT+ this.disposalCompanyId, { disposalSiteEditList: this.postData })
        .then(() => {
          // 処理業者詳細画面
          if (deleteFlag) {
            const params = new URLSearchParams();
            params.append("disposalCompanyId", this.disposalCompanyId);
            this.$router.push(`/entrust?${params.toString()}`);
          } else {
            this.$router.push(`/disposer/${this.disposalCompanyId}`);
          }
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isConfirmModalShow = false;
          this.isConfirmDeleteModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    /**
     * formデータをフォーマット
     * 不要なformId、disposalSiteDataを削除
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        let newData = JSON.parse(JSON.stringify(this.form)); // deep copy

        newData.map((d) => {
          if (!d.fax) delete d.fax;
          this.$delete(d, "formId");
          this.$delete(d, "disposalSiteData");
          // 住所の全角変換
          d.addressInfo.address = this.toFullWidthStringForAddressInfo(d.addressInfo.address);
          d.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(d.addressInfo.buildingName);
          this.prefectures.map((prefecture) => {
            if (prefecture.name === d.addressInfo.selectedPrefectureTemp) {
              d.addressInfo.prefecturesId = prefecture.id;
              delete d.addressInfo.selectedPrefectureTemp;
            }
            // 最終処分場
            if (d.finalDisposalSiteList) {
              for (let index = 0; index < d.finalDisposalSiteList.length; index++) {
                if (prefecture.name === d.finalDisposalSiteList[index].addressInfo.selectedPrefectureTemp) {
                  d.finalDisposalSiteList[index].addressInfo.prefecturesId = prefecture.id;
                  delete d.finalDisposalSiteList[index].addressInfo.selectedPrefectureTemp;
                }

                // 電話番号未入力なら削除
                if (!d.finalDisposalSiteList[index].tel) {
                  delete d.finalDisposalSiteList[index].tel;
                }

                // 事業場コード未入力なら削除
                if (!d.finalDisposalSiteList[index].finalDisposalStoreCode) {
                  delete d.finalDisposalSiteList[index].finalDisposalStoreCode;
                }
              }
            }
          });
        });
        resolve(newData);
      });
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];
      this.isDisableRegister = true;
      
      if(this.form.length <= 0) {
        this.isDisableRegister = false;
        this.errMsgs.push("処分場情報を入力してください。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const isValid = await this.runValidate();
      if (!isValid) {
        this.processing = false;
        this.isDisableRegister = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 都道府県をIDに変える、不要なプロパティ消す
      this.formatPostData().then((res) => {
        if (this.deleteFormFlag) {
          this.isConfirmDeleteModalShow = true;
        } else {
          this.isConfirmModalShow = true;
        }
        this.isDisableRegister = false;
        this.postData = res;
      });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },

    // モーダル内戻る押下
    closeConfirmModalHandler() {
      this.isConfirmModalShow = false;
      this.isConfirmDeleteModalShow = false;
      this.postData = null;
    },

    // モーダル内登録押下
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.disposalCompanyId = Number(this.$route.params.id);

    var self = this;
    this.getDisposerInfo(this.disposalCompanyId).then(function() {
      // APIの結果を格納後、チェックボックスの状態を更新する
      self.updateCheckboxStatus();
    });;
    this.getPrefecturesApi();
  },
};
</script>
